/**
 * Include all the pages CSS for minification
 * */
@import url("~@fontsource-variable/raleway/index.css");
@import url("~@fortawesome/fontawesome-free/css/all.css");
@import url("~bootstrap/dist/css/bootstrap.min.css");
@import url("~animate.css/animate.min.css");

/**
 * Anything below here is a shared style across all pages
 */

html,
body {
    font-family: 'Raleway Variable', sans-serif;
    height: 100%;
    width: 100%;
    margin: 0px;
    background: #EEEEEE;
}

::selection {
    color: white;
    background: orangered
}

:root {
    --component-background: white;
    --component-font: black;
    --page-background: #EEEEEE;
    --page-font: black;
    --link-color: #007bff;
    --link-hover: #0056b3;
    --border-color: #dee2e6;
}

body, html {
    background: var(--page-background);
    color: var(--page-font);
}

a {
    color: var(--link-color);
}

a:hover {
    color: var(--link-color);
}
    
main {
    background: var(--page-background);
}

.card {
    background: var(--component-background);
    color: var(--component-font);
}

.form-control, .form-control:focus, .form-control:active {
    background: initial;
    color: initial;
}

.form-control::placeholder {
    color: initial;
}

.table {
    color: var(--component-font);
}

header.site-header{
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.80);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 0.5em;
}
header.site-header h1 {
    color: orangered;
    margin: 0px;
    font-size: 1.5em;
}
header.site-header .menu-left{
    width: 50%;
}
header.site-header .menu-right{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    font-size: .8em;
}

header.site-header .menu-right a {
    padding-right: 1em;
    color: white;
    text-align: center;
    font-size: 1.5em;
    text-decoration: none;
}
header.site-header .menu-right a.active{
    color: orangered;
}

.outlet{
    padding-top: 80px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: .8em;
    margin: 0 auto;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .75);
    color: white;
    text-align: right;
    font-size: .5em;
}

.mk-page {
    max-width: 1400px;
    margin: 0 auto;
}

.spinner {
    border: 16px solid var(--page-background); /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}


.empty-message{
    width: 100%;
    text-align: center;
    padding: 3em;
}

.pagination {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    padding: 0.2em;
}
.pagination > .btn{
    margin-right: 1em; 
}
.has-tooltip {
    position: relative;
}

.has-tooltip .tooltip-text {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5em;
    position: absolute;
    z-index: 1;
}

.has-tooltip:hover .tooltip-text {
    visibility: visible;
}

.item-list {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.item {
    background-color: var(--component-background);
    background-size: cover;
    background-position: center center;
    width: calc(100% * 1/3 - 10px);
    height: 250px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.item-title {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    font-size: .6em;
}

.item-title:hover {
    display: block !important;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader[hidden]{
    display: none;
}


main .nav-tabs {
    border-bottom: 1px solid var(--border-color);
}

main .nav-tabs .nav-link.active,
main .nav-tabs .nav-link {
   color: var(--page-font);    
}

main .nav-tabs .nav-link.active {
    background: var(--page-background);
    border: 1px solid var(--border-color);
    font-weight: bold;
}

main .nav-tabs .nav-link:hover {
    border: 1px solid var(--border-color);
}

@media (prefers-color-scheme: dark) {
    :root {
        --component-background: #151515;
        --component-font: white;
        --page-background: #3a3a3a;
        --page-font: white;
	    --border-color: #333333;
        --link-color: #65afff;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width: 799px) {
    header.site-header h1 {
        font-size: 1.1em;
    }
    small {
        font-size: 70%;
    }
}
@media screen and (max-width: 499px) {
    .menu-text{
        display: none;
    }
}
@media screen and (max-width: 960px) {
    .item{
        width: calc(100% * 1/2 - 10px);
    }
}

@media screen and (max-width: 799px) {
    .item{
        width: calc(100% - 10px);
    }
}


.form-group {
    margin-bottom: 1rem;
}

label {
    margin-bottom: 0.5rem;
}

a {
    text-decoration: none;
}
